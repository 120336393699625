<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Inventory</h2>
      <div class="d-flex justify-content-between">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
          <router-link to="/dashboards/daftar-barang">
            <b-button variant="primary-2"> <i />Tipe Inventory </b-button>
          </router-link>
        </div>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
    </b-col>
    <div class="col-12 mt-16 mb-3">
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="table.items"
        :fields="fields"
        :busy.sync="isBusy"
        responsive="sm"
      >
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
      </b-table>
      <b-pagination
        class="mb-3"
        align="right"
        @input="changePage"
        v-model="table.currentPage"
        :per-page="table.perPage"
        :total-rows="table.total"
      />
    </div>
  </b-row>
</template>

<script>
import {
  BButton,
  BButtonGroup,
  BCol,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BSpinner,
  BTable,
  BPagination,
} from "bootstrap-vue";
import tableDataMixins from "../../../../../mixins/tableDataMixins";
export default {
  name: "ListTypeInventory",
  data() {
    return {
      fields: [
        {
          label: "Items",
          key: "id_type_inventory",
          sortable: true,
        },
        {
          label: "Ket",
          key: "note",
          sortable: true,
        },
        {
          label: "Stock",
          key: "total",
          sortable: true,
        },
      ],
    };
  },
  components: {
    BSpinner,
    BButtonGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BRow,
    BInputGroup,
    BCol,
    BTable,
    BPagination,
  },
  mixins: [tableDataMixins],
  computed: {
    incomingTypeInventorys() {
      return this.$store.state.incomingTypeInventory.incomingTypeInventorys;
    },
    totalPages() {
      return this.$store.state.incomingTypeInventory.totalPages;
    },
    isBusy() {
      return this.$store.state.incomingTypeInventory.isBusy;
    },
  },
  methods: {
    async fetchData() {
      this.table.loading = this.isBusy;
      await this.$store.dispatch(
        "incomingTypeInventory/getIncomingInventorys",
        {
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.search,
        }
      );
      this.table.items = this.incomingTypeInventorys;
      this.table.total = this.totalPages;
    },
    async destroyIncomingInventory(id) {
      let formData = new FormData();
      formData.append("id", id);

      await this.$store
        .dispatch("incomingTypeInventory/destroyIncomingInventory", formData)
        .then(() => {
          this.$bvToast.toast(
            "Data Incoming Type Inventory Berhasil Di Hapus",
            {
              title: "Success",
              variant: "success",
              solid: true,
            }
          );
        });
      await this.$store.dispatch("incomingTypeInventory/getIncomingInventorys");
    },
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);
      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
  refetch() {
    this.fetchData();
  },
};
</script>

<style scoped></style>
